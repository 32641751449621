// topbar menu styles start
.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px
}

// .modalTable{
// overflow-y: hidden;
// }
.modalTbody {
    max-height: 500px !important;
    overflow-y: scroll;
    margin: 10px;
}

.topBarImageAlignment {
    text-align: center;
}

.themeColorText {
    color: #f17038;
}

.paginationcss {
    margin-right: 0;
}

.topbarMenu {
    width: 200px;
    border-radius: 0;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
}

.theme-white {
    border: 1px solid #ddd !important;
    background: white !important
}

.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
    height: 38px !important;
}


// topbar menu styles end
.form input {
    background: white;
}

.form:focus,
.form:active {
    outline: none;
    border-color: $color-accent;
}


body .p-datatable .p-datatable-tbody>tr.p-highlightHoliday {
    background-color: #fbff2b;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightWeekend {
    background-color: #1efd00;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightLessThan6Hrs {
    background-color: #ff0000;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: white;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2em;
    height: 38px !important;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}

body .p-button {
    background-color: #3475aa
}

//autocomplete multi-select
.p-autocomplete-input-token {
    height: 25px;
}

.p-autocomplete-token.p-highlight {
    margin-bottom: 2px;
}

// Dasboard 
.dashBoardCardWithScroll {
    height: 390px;
}

//Scrollable table with Sticky Header
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}

.checkListdiv {
    display: flex;
    font-size: 18px;
    justify-content: center;
    margin-bottom: 10px;
}

.checkListspan {
    display: flex;
    font-size: 15px;
    justify-content: center;
    color: #3475aa;
    font-weight: 500;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
}

//kpiTable
.kpiTable {
    background-color: #3475aa;
    color: black;
}

.colorLegend {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

body .p-multiselect-panel .p-multiselect-items {
    color: black
}

.p-multiselect-item.p-highlight {
    background-color: #3475aa;
    color: black
}

.p-multiselect-label-container {
    height: 35.5px;
    padding-top: 0.35rem;
}

body .p-input-overlay-visible {
    z-index: 9999 !important;
}

.color-picker__popover {
    z-index: 9999;
}

.calendar .rbc-off-range-bg {
    background-color: #e5e5e5;
}

.textElipses {
    font-weight: 600;
    cursor: pointer;
    color: $color-accent;
}

.p-dropdown .p-dropdown-trigger {
    height: 100%;
}

.back {
    // background: #d6d7da;
    font-weight: 700;
    margin-bottom: 10px;
    cursor: pointer;

}

.back:hover {
    background: #f2f2f2;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.05);
}

// .css-vj8t7z{
//     min-height: 0 !important
// }
// .css-1hwfws3{
//     position: static !important;
// }
// .css-10nd86i react-select{
//     height: 33px
// }
// .css-2o5izw .react-select__control .react-select__control--is-focused{
//     height: 33px;
// }

.botmessagesText {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 8px;
    padding-left: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: 5px;
    align-self: flex-start;
    width: 90%;
    border-color: red
}

.usermessagesText {
    background-color: #f0f1ef;
    padding-top: 5px;
    padding-left: 7px;
    margin-right: 5px;
    margin-top: 7px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 90%;
    text-align: left
}

.categoryAddIcon {
    color: white;
    padding: 3.5px;
    margin-left: 5px;
    border-radius: 22px;
    font-size: 22px;
    cursor: pointer
}

.deleteModalBody {
    text-align: center;
}

.deleteModalText {
    margin-bottom: 10px;
    font-size: 15px;
}

.deleteModalWidth {
    max-width: 500px;
}

.detailsImgStyle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.inventory-check-tbody {
    display: block;
    max-height: 350px;
    overflow-y: scroll;
}

.inventory-check-thead,
.inventory-check-tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.table thead th {
    vertical-align: middle !important;
}

.inventoryCheckRowTextColor {
    text-align: center;
    color: black
}

.inventoryCheckRow {
    text-align: center;
    color: whitesmoke !important;
    border: 1px solid white !important
}

.add_to_orders_btn {
    margin: auto;
    padding: 0px 7px;
}

.sidemenuTopSpace {
    margin-top: 2px
}

/* .card-scroll{
    overflow-y: auto;

} */
.navContainer {
    position: relative;
    height: calc(100vh - 52px);
    overflow: hidden;
    transition: all .15s;
}

.dashboardheading {
    padding: 15px 10px 0 10px;
}


.tableCardBody {
    /* float:left; */
    /* overflow-y: auto; */
    /* max-height: 560px; */
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;

}

.serachAlignment {
    text-align: right;
    display: flex
}

@media screen and (min-width: 3500px) {
    .modal-dialog--header {
        max-width: 1750px;
        min-height: 1500px;
    }

    .modal-content {
        min-height: 400px
    }
}

@media screen and (max-width: 1000px) {
    .serachAlignment {
        text-align: left
    }
}

@media screen and (min-width: 1000px) {
    .serachAlignment {
        justify-content: flex-end;
        /* margin-right: 10px */
    }
}

.statusText {
    color: white;
    text-align: center;
    padding: 3px;
    border-radius: 5px;
    font-size: 11px
}

.cardHeader {
    font-weight: bold;
    padding: 5px 10px;
    padding-top: 15px;
    background-color: white !important
}

.cardBodyText {
    color: white;
    font-weight: bold;
    padding: 15px
}

.dashboardIcon {
    font-size: 30px
}

.rowSpace {
    padding: 10px 30px
}

.cardShadow {
    box-shadow: 5px 5px 15px #f4f7f8;
}

.dashBoardRowSpace {
    margin-right: 15px
}

.dashboardCardScroll {
    overflow-y: auto;
    max-height: 600px;
    margin-right: 0px
}

.user-img {
    border-radius: 30px;
}

.dropdownAlign {
    text-align: right
}

.userNameClick {
    cursor: pointer;
}

.dropdownMenuStyle {
    margin-top: 16px;
    margin-right: 8px;
    padding: 0px 0px 9px 0px
}

.dropdownDivider {
    margin-top: 0px
}

.dropdownHeader {
    /* background-color: black; */
    color: white;
    text-align: center;
    background-color: black
}

img {
    width: 65px;
    height: 60px;
    border-radius: 80px;
    margin-top: 10px;
}

.dropdownTextOnHover:hover {
    background-color: black;
    color: white;
    font-weight: bold;
}

.userNameStyle {
    font-weight: bold;
    padding: 10px 0px;
    font-size: 14.5px
}

/* for extra small device and small */
@media (max-width: 767px) {
    .cardSmSpace {
        margin: 10px
    }
}

/* for extra medium device */
@media (max-width: 991px) {
    .cardMdSpace {
        margin-top: 10px
    }
}

.logoutOnHover:hover {
    text-decoration: none;
}

/* for extra medium device */
@media (max-width: 991px) {
    .userNameDisplay {
        display: none
    }
}

/* Table look changes start */

body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
    background-color: #ffffff;
    border: none;
    border-bottom: 0;
}

body .p-paginator {
    border: 1px solid #e5e5e5;
    background-color: #fff !important;
}

body .p-datatable .p-datatable-thead>tr>th {
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
}

body .p-datatable .p-datatable-tbody>tr>td {
    border: none;
}


/* Table look changes end */

body .pi {
    font-size: 1em;
}

.imgStyles {
    width: 30px;
    height: 30px;
    margin-top: 0px;
    border-radius: 50%;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: #3475aa;
    color: #ffffff;
}

body .p-datatable .p-datatable-tbody>tr.p-highlight {
    background-color: #64677778;
    color: #ffffff;
}

body .p-datatable .p-datatable-tbody>tr:nth-child(even).p-highlight {
    background-color: #64677778;
    color: #ffffff;
}

body .p-datatable .p-datatable-tbody>tr.p-highlight {
    background-color: #0fda7378 !important;
}

.storageTypeCount_hover:hover {
    color: $color-accent !important;
    font-weight: bold;
}

.textCapitalize {
    text-transform: capitalize;
}

.word_align {
    width: 220px;
    max-height: 100px;
    overflow-y: auto;
    white-space: pre-wrap;
    word-break: break-all;
}