.react-select {
  font-size: 12px;
}

.react-select__control {
  transition: all 0.3s;
  border-radius: 3px !important;
  @include themify($themes) {
    border: 1px solid themed('colorFieldsBorder');
    background-color: themed('colorBackground');
  }

  &.react-select__control--is-focused, &:hover {
    border-color: $color-accent !important;
    box-shadow: none;
    background: transparent;
  }
}

.css-vj8t7z{
  min-height: 32px !important;
}

.css-1hwfws3{
  height: 32px !important;
  align-content: center !important;
}

.react-select__indicator-separator {
  display: none;
}